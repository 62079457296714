import React from 'react';
import './App.css';
//import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { Helmet } from 'react-helmet'
import { Grid, Divider } from 'semantic-ui-react'
//import { Image, Container, Grid, Label, Icon, Menu, Visibility, Rail, Segment, Divider, Popup, Button, Sticky, Loader } from 'semantic-ui-react'
//import 'semantic-ui-css/semantic.min.css'
//import {Helmet} from 'react-helmet'

function App() {

  return (
    <div className="App">
    <Helmet>
     <style>{'body { background-color: #111111; }'}</style>
      <title>Durnal Construction</title>
    </Helmet>
    <center>
    <h1 className="h1">Durnal Construction</h1>
    <h4 className="h4">Construction Contractor since 1999<br />
    Contact: Lane Durnal<br />
    Phone: (405) 413-0619</h4>
    <Divider />
    <Grid>
        <Grid.Row columns={3} divided>
          <Grid.Column >
            <div className="App"style={{ display:"flex", minHeight:"10vh", flexDirection:"column" }}>
              <Carousel
                  width="70%"
                  emulateTouch
                  infiniteLoop autoPlay swipeable={true}
                >
                <div> <img class="img1" src="https://durnalconstruction.com/images/img1.jpg" alt=""/> </div>
                <div> <img class="img1" src="https://durnalconstruction.com/images/img2.jpg" alt=""/> </div>
                <div> <img class="img1" src="https://durnalconstruction.com/images/img3.jpg" alt=""/> </div>
                <div> <img class="img1" src="https://durnalconstruction.com/images/img4.jpg" alt=""/> </div>
                <div> <img class="img1" src="https://durnalconstruction.com/images/img5.jpg" alt=""/> </div>
                <div> <img class="img1" src="https://durnalconstruction.com/images/img6.jpg" alt=""/> </div>
                <div> <img class="img1" src="https://durnalconstruction.com/images/img7.jpg" alt=""/> </div>
                <div> <img class="img1" src="https://durnalconstruction.com/images/img8.jpg" alt=""/> </div>
                <div> <img class="img1" src="https://durnalconstruction.com/images/img9.jpg" alt=""/> </div>
              </Carousel>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      </center>
    </div>
  );
}

export default App;
